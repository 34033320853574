import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-overview-header',
    templateUrl: './overview-header.component.html',
    styleUrls: ['./overview-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class OverviewHeaderComponent {
  @Input() title: string;
}
